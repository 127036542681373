import { h, Component } from 'preact';
import { Route, Router } from 'preact-router';
import { CSSTransition } from 'react-transition-group';
import style from './app.css';

import Home from '../routes/home';
import FindOut from '../routes/FindOut';

function withTransition(WrappedComponent) {
	return function TransitionComponent(props) {
	  return (
		<CSSTransition
		  key={props.url}
		  timeout={300}
		  classNames={{
			enter: style.fadeEnter,
			enterActive: style.fadeEnterActive,
			exit: style.fadeExit,
			exitActive: style.fadeExitActive,
		  }}
		>
		  <WrappedComponent {...props} />
		</CSSTransition>
	  );
	};
  }
  
  const HomeWithTransition = withTransition(Home);
	const FindOutWithTransition = withTransition(FindOut);


class App extends Component {
  // State to track the current URL
  state = { currentUrl: '/' };

  // Method to update the current URL
  handleRoute = e => {
    this.setState({ currentUrl: e.url });
  };


  render() {
	return (
	  <div id="app">
		<Router onChange={this.handleRoute}>
		  <Route path="/" component={HomeWithTransition} />
		  <Route path="/findout/" component={FindOutWithTransition} />
		  {/* other routes */}
		</Router>
	  </div>
	);
  }
  
}

export default App;
